<template>
  <div>
    <template v-if="selectedDirectory.dateFormatted">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 100%"
      />
      <v-divider class="mt-10" />
    </template>
    <v-data-table
      class="mb-0 style-table-arquivos-dados"
      :headers="headers"
      :items="_items"
      :search.sync="search"
      :headerProps="{ sortByText: 'Ordenar Por' }"
      :footer-props="{ itemsPerPageText: 'Itens por Página' }"
      no-data-text="Nenhum arquivo encontrado"
      no-results-text="Nenhum registro correspondente encontrado"
      mobile-breakpoint="920"
      :hide-default-footer="loading"
      :hide-default-header="loading"
      :loading="loading"
      loading-text="Carregando arquivos ..."
    >
      <template v-slot:[`item.nome`]="{ item }" name="fade">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div 
              v-bind="attrs" 
              v-on="on" 
              class="text-truncate-column">
              <a 
                href="javascript:void(0)" 
                @click="downloadFile(item)">
                {{ item.nome }}
              </a>
              <v-btn
                v-if="_downloadProgressData[item.id] !== undefined ? true : false"
                color="gray"
                class="ml-2 mb-2"
                fab
                icon
                height="18"
                width="18"
                :loading="true"
              />
            </div>
          </template>
          <span>Baixar Arquivo -> {{ item.nome }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.size`]="{ item }">
        {{ item.size | bytesToSize }}
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | parseToDateTimeBR }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :loading="_downloadProgressData[item.id] != undefined"
          min-width="0"
          class="px-1 ml-n1"
          fab
          icon
          x-small
          @click="downloadFile(item)"
        >
          <v-icon small>mdi-download</v-icon>
        </v-btn>
        <v-btn
          v-if="userIsAdmin || user.id === item.usuario_id"
          min-width="0"
          class="px-1 ml-n1"
          fab
          icon
          x-small
          @click="openDialogDelete(item.id, item.nome)">
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <dialog-delete 
      :dialog.sync="dialogDelete"
      @submitTriggered="deleteFile(dialogDeleteData.id)"
    >
      <template slot="title">Deletar Arquivo #{{dialogDeleteData.id}}</template>
      <template slot="body">Tem certeza que deseja deletar o arquivo <strong>{{ dialogDeleteData.titulo }}</strong>?</template>
    </dialog-delete>
  </div>
</template>

<script>
import DateFormatterMixins from "@/mixins/dateFormatterMixins.js";
import FilesService from "../../../services/FilesService";

export default {
  components: {
    DialogDelete: () => import('@/components/general/DialogDelete'),
  },

  mixins: [DateFormatterMixins],

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    downloadProgressData: {
      type: Object,
      required: true,
    },
    selectedDirectory: {
      type: Object,
      required: false  
    }
  },

  data() {
    return {
      headers: [
        {
          text: "Nome do Arquivo",
          value: "nome"
        },
        {
          text: "Tamanho",
          value: "size"
        },
        {
          text: "Enviado por",
          value: "enviado_por"
        },
        {
          text: "Enviado em",
          value: "created"
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
          class: "pl-4"
        }
      ],
      search: "",
      dialogDeleteData: {},
      dialogDelete: false
    }
  },

  computed: {
    userIsAdmin() {
      return this.$store.getters.getUserIsAdmin;
    },
    user() {
      return this.$store.getters.getUser;
    },
    _items: {
      get() {
        return this.items;
      },
      set(newValue) {
        this.$emit("update:items", newValue);
      }
    },
    _downloadProgressData: {
      get() {
        return this.downloadProgressData;
      },
      set(newValue) {
        this.$emit("update:downloadProgressData", newValue);
      }
    }
  },

  methods: {
    openDialogDelete(itemId, itemTitle) {
      this.dialogDeleteData = { id: itemId, titulo: itemTitle };
      this.dialogDelete = true;
    },
    deleteFile(id) {
      FilesService.delete(id)
        .then(() => {
          this.$toast.success(`Arquivo excluído com sucesso`, "", { position: "topRight" });
          this.$emit("rechargeUploadedFiles");
        }).catch(err => {
          console.error(err);
          this.$toast.error(`Erro ao excluír arquivo`, "", { position: "topRight" });
        });

      this.dialogDelete = false;
    },
    downloadFile(item) {
      if (this._downloadProgressData[item.id] !== undefined) return;

      const id = item.id;

      this.$set(this._downloadProgressData, id, { id }); 
      this.$toast.info('Preparando seu arquivo para download.', '',{ position:'topRight', timeout: 2000 });

      FilesService.download(
        id, 
        (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          const isComputable = progressEvent.lengthComputable;
          this.$set(this._downloadProgressData, id, { progress, isComputable, id });
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.nome);
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.error(err);
          this.$toast.error(`Erro ao baixar arquivo '${item.nome}'.`, "", { position: "topRight" });
        })
        .finally(() => {
          this.$delete(this._downloadProgressData, id);
        });
    }
  }
}

</script>

<style>
.style-table-arquivos-dados table thead tr th {
  font-weight:400;
  font-size:14px;
  height: 33px;
}

.style-table-arquivos-dados table tbody tr td {
  word-wrap: break-word;
  max-width: 240px;
  font-size: 12.5px;
  padding: 0px 8px !important;
  height: 45px;
}

.style-table-arquivos-dados table tbody tr td:nth-child(6) {
  white-space: nowrap !important;
}

@media screen and (max-width: 920px) {
  .style-table-arquivos-dados table tbody tr td  {
    max-width: 100% !important;
  }
}
</style>