var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedDirectory.dateFormatted)?[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"100%"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"})]:_vm._e(),_c('v-data-table',{staticClass:"mb-0 style-table-arquivos-dados",attrs:{"headers":_vm.headers,"items":_vm._items,"search":_vm.search,"headerProps":{ sortByText: 'Ordenar Por' },"footer-props":{ itemsPerPageText: 'Itens por Página' },"no-data-text":"Nenhum arquivo encontrado","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":"920","hide-default-footer":_vm.loading,"hide-default-header":_vm.loading,"loading":_vm.loading,"loading-text":"Carregando arquivos ..."},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate-column"},'div',attrs,false),on),[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v(" "+_vm._s(item.nome)+" ")]),(_vm._downloadProgressData[item.id] !== undefined ? true : false)?_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"color":"gray","fab":"","icon":"","height":"18","width":"18","loading":true}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Baixar Arquivo -> "+_vm._s(item.nome))])])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("bytesToSize")(item.size))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-1 ml-n1",attrs:{"loading":_vm._downloadProgressData[item.id] != undefined,"min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")])],1),(_vm.userIsAdmin || _vm.user.id === item.usuario_id)?_c('v-btn',{staticClass:"px-1 ml-n1",attrs:{"min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.openDialogDelete(item.id, item.nome)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}}],null,true)}),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialogDelete=$event},"submitTriggered":function($event){return _vm.deleteFile(_vm.dialogDeleteData.id)}}},[_c('template',{slot:"title"},[_vm._v("Deletar Arquivo #"+_vm._s(_vm.dialogDeleteData.id))]),_c('template',{slot:"body"},[_vm._v("Tem certeza que deseja deletar o arquivo "),_c('strong',[_vm._v(_vm._s(_vm.dialogDeleteData.titulo))]),_vm._v("?")])],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }